exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-join-wait-list-js": () => import("./../../../src/pages/join-wait-list.js" /* webpackChunkName: "component---src-pages-join-wait-list-js" */),
  "component---src-pages-managed-services-js": () => import("./../../../src/pages/managed-services.js" /* webpackChunkName: "component---src-pages-managed-services-js" */),
  "component---src-pages-v-1-index-js": () => import("./../../../src/pages/v1-index.js" /* webpackChunkName: "component---src-pages-v-1-index-js" */)
}

